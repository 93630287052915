import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  Menu,
  MenuItem,
  MenuList,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MegaMenuItem from "./MegaMenuItem";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
function NavbarItem(props) {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [activeMenu, setActiveMenu] = React.useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://khuranaandkhurana-strapi.ntb.one/api/navbars"
        );
        setData(response.data.data);
        setLoading(false);

      } catch (error) {
        console.error("Error fetching data from Strapi:", error);
      }
    };

    fetchData();
  }, []);

  const handleOpenUserMenu = (event, id) => {
    setAnchorElUser(event.currentTarget);
    setActiveMenu(id);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    setActiveMenu(null);
  };

  return (
    <>
     {loading ? (
          //  <div style={{display:"flex",gap:"30px", marginRight:"30px", alignItems:"center"}}>
          <div style={{ display: isMobile ? "block" : "flex", flexDirection: isMobile ? "column" : "row", gap: isMobile ? "50px" : "30px", marginRight: "30px", alignItems: "center" }}>
           <Skeleton height={20} width={100} /> 
           <Skeleton height={20} width={100}  /> 
           <Skeleton height={20} width={100}  /> 
           <Skeleton height={20} width={100} /> 
           <Skeleton height={20} width={100} /> 
 
           </div>
        ) : (
         
     data?.map((item) => (
        <Button
          key={item.id}
          className={item?.attributes?.megaMenu ? "has-submenu" : ""}
          sx={{
            mx: 4,
            color: "black",
            display: "block",
            "&:hover": { backgroundColor: "white ! important" },
          }}
          onClick={(e) => handleOpenUserMenu(e, item?.id)}
          onMouseEnter={(e) => props.updateSlider(e.currentTarget)}
          onMouseOver={(e) => props.updateSlider(e.currentTarget)}
          disableRipple
          style={{
            textTransform: "capitalize",
            fontSize: "15px",
            color: "#425563",
            fontStyle: "normal",
            fontWeight: "400",
            letterSpacing: ".58px",
            width: "auto",
            border: "1px solid white",
          }}
        >
           {item?.attributes?.megaMenu?.link ? (
        <Link
          to={`${process.env.PUBLIC_URL + item?.attributes?.megaMenu?.link}`}
          style={{ my: 2, color: "#425563", display: "block" }}
        >
          {item?.attributes?.title}
        </Link>
      ) : (
        <div style={{ my: 2, color: "#425563", display: "block" }}>
          {item?.attributes?.title}
        </div>
      )}
          {/* <div style={{ my: 2, color: "#425563", display: "block" }}>
            {item?.attributes?.title}
          </div> */}

          {(() => {
            if (item?.attributes?.megaMenu && Boolean(activeMenu == item?.id) && !item?.attributes?.megaMenu?.link) {
              return (
                <Menu
                  sx={{ mt: "45px" }}
                  keepMounted
                  disablePortal
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  open={Boolean(activeMenu == item.id && anchorElUser)}
                  onClose={handleCloseUserMenu}
                  onBlur={() => setTimeout(handleCloseUserMenu, 250)}
                >
                  <div style={{ backgroundColor: " #F2F3F4", height: "auto" }}>
                    <Grid
                      container
                      spacing={2}
                      sx={{ color: "#F2F3F4", marginBottom: "-8px" }}
                    >
                      {Array.isArray(item?.attributes?.megaMenu) && item?.attributes?.megaMenu?.map((column, index) => (
                        <Grid
                          key={index}
                          item
                          xs={12}
                          sm={Math.max(12 / item?.attributes?.megaMenu?.length, 6)}
                          md={Math.max(12 / item?.attributes?.megaMenu?.length, 3)}
                        >
                          {column?.map((megaitem, idx) => (
                            <MenuList
                              key={megaitem.id}
                              sx={{
                                backgroundColor: "#F2F3F4",
                                margin: "0",
                                padding: "0",
                              }}
                            >
                              <MegaMenuItem
                                font={'"Garamond-Regular",georgia,serif'}
                                listItem={megaitem}
                                handleCloseUserMenu={() => {}}
                                color={"#b23427"}
                                style={{
                                  marginBottom: "5px",
                                  textTransform: "capitalize",
                                  fontSize: "15px",
                                  margin: 0,
                                }}
                                // fontWeight="bold"
                              />
                              <div style={{
                                marginBottom:"12px",
                              }}>
                              {megaitem.lists.map((listItem) => {
                                return (
                                  <MegaMenuItem
                                    key={megaitem.id}
                                    arrow
                                    font={
                                      '"HelveticaNeue-Roman",arial,helvetica,sans-serif'
                                    }
                                    style={{
                                      textTransform: "capitalize",
                                      fontSize: "14px",
                                      fontStyle: "normal",
                                      fontWeight: "400",
                                    }}
                                    listItem={listItem}
                                    handleCloseUserMenu={handleCloseUserMenu}
                                  />
                                );
                              })}
                              </div>
                            </MenuList>
                          ))}
                        </Grid>
                      )
                      )}
                    </Grid>
                  </div>
                </Menu>
              );
            }
          })()}
        </Button>
      ))
     )} 
    </>
  );
}

export default NavbarItem;

