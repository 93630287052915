import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

const Overview = () => {
  const [over, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://khuranaandkhurana-strapi.ntb.one/api/overviews"
        );
        setData(response.data.data);
        setLoading(false);

        console.log("overview", response.data.data);
      } catch (error) {
        console.error("Error fetching data from StrApi:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div>
    <Box>
        {loading ? (
          <>
          <Skeleton count={6} />
          <br />
          <Skeleton count={6} />
          </>
        ) : (
        over.map((overview, index) => (
          <Typography key={index} variant="body1" align="center">
            <center>
              <p
                style={{
                  textAlign: "justify",
                  marginLeft: "40px",
                  marginRight: "40px",
                  fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                  fontSize: "16px",
                  lineHeight:"30px",
                  fontStyle: "normal",
                  fontStretch:"normal",
                  color:"rgb(66, 85, 99)"
                }}
              >
                {overview.attributes?.title}
              </p>
            </center>

            <br />
            <p
              style={{
                textAlign: "justify",
                marginLeft: "40px",
                marginRight: "40px",
                fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                fontStyle: "normal",
                fontStretch:"normal",
                fontSize: "16px",
                lineHeight:"30px",
                // fontWeight: "400",
                color:"rgb(66, 85, 99)"
              }}
            >
              {overview.attributes?.subheading}
            </p>
          </Typography>
        ))
        )}
    </Box>
  </div>
);
};

export default Overview;

