import { Box } from '@mui/material'
import React from 'react'

export default function Slider({width="0px",offset="0px", show=false}) {
  return (
    <Box sx={{
        display: { xs: 'none', md: 'block' },
        height: "8px",
        width: "100%"
    }}>
        <div style={{height:"100%",width,marginLeft:offset,backgroundColor:"#b23427",transition:"all 0.5s ease", display:show?"block":"none"}}>
        </div>
    </Box>
  )
}
