import React,{Fragment} from 'react';
import {Link} from "react-router-dom";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {Typography} from "@mui/material";
function Member(props) {
    const teamMemberURl = `/team-member/${props.name.split(' ').join('-').toLocaleLowerCase()}?id=${props.id}`;
    return (
        <Fragment>
            {props.type === 'page' ? (
                <div className="col-sm-6 col-lg-3">
                    <div className="team-mem-item">
                        <figure className="member-pic">
                            <Link to={`${process.env.PUBLIC_URL + teamMemberURl}`}>
                                <img src={require('../../../assets/img/' + props.profilePic)} alt={props.name}/>
                            </Link>
                        </figure>
                        
                        <div className="member-info">
                            <h5><Link to={`${process.env.PUBLIC_URL + teamMemberURl}`} className="" style={{color:"brown",fontFamily:"Garamond-Regular,georgia,serif",fontStretch:"normal",fontStyle:"normal",fontWeight:"400",fontSize:"20px"}}>{props.name}</Link></h5>
                            <br></br>
                            <span className="designation" style={{color:"#425563",fontFamily:"HelveticaNeue-Bold,arial,helvetica,sans-serif",fontSize:"16px"}}>{props.designation}</span>
                            <br></br>
                            <br></br>
                            <h5><Link to={`${process.env.PUBLIC_URL + teamMemberURl}`}  style={{color:"#425563",fontFamily:"HelveticaNeue-Bold,arial,helvetica,sans-serif",fontSize:"16px"}}>{props.place}</Link></h5>
                            <br></br>
                            <h5 ><Link to={`${process.env.PUBLIC_URL + teamMemberURl}`} className="" style={{color:"brown",fontFamily:"HelveticaNeue-Bold,arial,helvetica,sans-serif",fontSize:"16px"}}>{props.link }<ArrowRightAltIcon/></Link></h5>
                            <br></br>
                        </div>
        
                    </div>
                </div>
            ):(
                <div className="team-mem-item">
                    <figure className="member-pic">
                        <Link to={`${process.env.PUBLIC_URL + teamMemberURl}`}>
                            <img src={require('../../../assets/img/' + props.profilePic)} alt={props.name}/>
                        </Link>
                    </figure>
                    <div className="member-info">
                        <h5><Link to={`${process.env.PUBLIC_URL + teamMemberURl}`} className="">{props.name}</Link></h5>
                        <span className="designation">{props.designation}</span>
                    </div>
                </div>
            )}
        </Fragment>
    );
}

export default Member;